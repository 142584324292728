<template>
  <form @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <div class="form__item">
      <h4 class="no-margin">Введите код подтверждения из СМС</h4>
    </div>
    <div class="form__item">
      <InputComponent
        v-model="form.confirmCode"
        title="Код"
        :mask="vMaskStr"
        :disabled="loading"
        autofocus
      />
    </div>
    <div class="form__error" v-if="error">{{ error }}</div>
    <div v-if="error" class="form__actions">
      <button
        @click.prevent="resendCode"
        class="btn-main btn-auto"
        type="button"
        :disabled="timer > 0"
      >
        <LoadingIndicator v-if="resendLoading"/>
        <template v-else-if="timer > 0">Повторная отправка кода: {{ timer }} с.</template>
        <template v-else>Повторно отправить код</template>
      </button>
    </div>
    <div class="form__actions">
      <button
        type="button"
        @click.prevent="$emit('changeStep', codes.CHOICE_CODE)"
        class="btn-main"
      >
        Назад
      </button>
      <button class="btn-main" type="submit" :disabled="loading || !form.confirmCode">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Далее</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";
import axios from "axios";

export default {
  name: "AuthConfirm",
  props: {
    codes: Object,
  },
  data() {
    return {
      token_2fa: null, // временный токен для двухфакторной аутентификации
      codeLength: 6,
      phoneMessage: null,
      loading: false,
      resendLoading: false,
      error: null,
      codeError: null,
      timer: 0,
      interval: null,
      duration: 10000, // 10 секунд показываю что код уже отправлен
      form: {
        confirmCode: null,
        password: null,
      }
    }
  },
  async beforeCreate() {
    let fd = new FormData();
    fd.set("grant_type", "password")
    fd.set("username", this.$store.state.user.phoneNumber)
    let fdStr = new URLSearchParams(fd).toString()
    await axios.post(`https://${this.$store.state.url}/authorizationService/token`, fdStr)
      .then(() => {
        console.log('ok')
      })
      .catch((error) => {
        if (error.response) {
          let headers = error.response.headers
          this.token_2fa = headers['x-loymax-2fa']
        }
      });
  },
  computed: {
    vMaskStr() {
      return "#".repeat(this.codeLength)
    },
  },
  methods: {
    formSubmit() {
      if (!this.loading) {
        this.loading = true;
        this.error = null;
        let fd = new FormData();
        fd.set("grant_type", "password")
        fd.set("password", this.form.confirmCode)
        let fdStr = new URLSearchParams(fd).toString()
        axios.post(`https://${this.$store.state.url}/authorizationService/token`, fdStr, {
          headers: {
            "X-Loymax-2FA": this.token_2fa
          }
        })
          .then(({ data }) => {
            this.loading = false;
            let token = data && data.access_token ? data.access_token : null
            if (token) {
              // сохраняем полученный токен
              this.$store.dispatch('setAuthToken', { token: token })
            }
            this.$emit('changeStep', this.codes.CARD_SET_CODE)
          })
          .catch((error) => {
            this.error = "Неправильный код"
            this.loading = false;
            if (error.response) {
              console.log('error.res', error.response)
            }
          });
      }
    },
    resendCode() {
      if (!this.resendLoading) {
        this.resendLoading = true;
        axios.post(`https://${this.$store.state.url}/authorizationService/${this.$store.state.version}/auth/SendConfirmationCode`, {}, {
          headers: {
            "X-Loymax-2FA": this.token_2fa
          }
        })
          .then(() => {
            this.resendLoading = false;
            this.countdown();
          })
          .catch((error) => {
            this.resendLoading = false;
            if (error.response) {
              console.log('error.res', error.response)
            }
          });
      }
    },
    countdown() {
      clearInterval(this.interval)
      this.timer = this.$store.state.config.code_timer
      this.interval = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(this.interval)
          return
        }
        this.timer -= 1
      }, 1000)
    },
  },
  components: {
    InputComponent,
    LoadingIndicator,
  }
}
</script>

<template>
  <form class="form form-choice" novalidate spellcheck="false">
    <div class="form__item">
      <h4 class="no-margin">Хотите зарегистрировать карту?</h4>
    </div>
    <div class="form__actions">
      <button @click="back" class="btn-main" type="button"> Нет </button>
      <button @click="next" class="btn-main" type="button"> Да </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "ChoiceComponent",
  props: {
    codes: Object,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    back() {
      this.$router.push({ name: "home" })
    },
    next() {
      this.$emit('changeStep', this.codes.AUTH_CONFIRM_CODE)
    }
  }
}
</script>

<style lang="stylus">
.form-choice {
  .form__error {
    justify-content flex-start
    text-align left
  }
}
</style>
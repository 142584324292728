<template>
  <form @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <div class="form__item">
      <InputComponent
        v-model.trim="form.cardNumber"
        title="Введите карту"
        :disabled="loading"
        autofocus
      />
    </div>
    <div class="form__error" v-if="error">{{ error }}</div>
    <div class="form__actions">
      <button @click.prevent="prevStep" type="button" class="btn-main">
        Назад
      </button>
      <button class="btn-main" type="submit">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Привязать</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";

export default {
  name: "CardSet",
  props: {
    codes: Object,
  },
  data() {
    return {
      loading: false,
      error: null,
      form: {
        cardNumber: undefined,
        cvcCode: undefined,
      }
    }
  },
  created() {
    if (sessionStorage.card) {
      this.form.cardNumber = sessionStorage.card
    }
  },
  methods: {
    prevStep() {
      this.$emit("changeStep", this.codes.CHOICE_CODE)
    },
    nextStep() {
      this.$emit('changeStep', this.codes.QUESTIONS_CODE)
    },
    formSubmit() {
      if (!this.loading) {
        this.loading = true
        this.error = null;
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        this.$store.dispatch("SET_CARD", {
          data: { ...this.form },
          token: localStorage.getItem("auth-token")
        })
            .then(({ data }) => {
              if (data.result && data.result.state === "Success") {
                this.nextStep()
              } else if (data.result && data.result.state === "Error") {
                this.error = data.result.message
              } else if (data.result && data.result.state === "ValidationError") {
                this.error = "Ошибка. Введите данные"
              }
              this.loading = false
            })
            .catch(() => {
              this.error = "Ошибка добавления карты"
              this.loading = false
            });
      }
    }
  },
  components: {
    InputComponent,
    LoadingIndicator,
  }
}
</script>

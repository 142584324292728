<template>
  <main class="main reg">
    <section class="section">
      <div class="container">
        <div class="reg__content">
          <component
            :is="activeStep.component"
            @changeStep="setActive"
            v-if="activeStep"
            :codes="codes"
            :isBonusCard="activeStep.isBonusCard"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ChoiceComponent from "@/views/bonus_cards/components/ChoiceComponent";
import AuthConfirm from "@/views/bonus_cards/components/AuthConfirm";
import CardSet from "@/views/bonus_cards/components/CardSet";
import FinishCardSet from "@/views/bonus_cards/components/FinishCardSet";
import QuestionsComponent from "../home/components/QuestionsComponent";
import EmailConfirm from "@/views/home/components/EmailConfirm";

export default {
  name: "BonusCards",
  data() {
    return {
      activeStepCode: this.$store.state.codes.bonus_cards.CHOICE_CODE,
    }
  },
  beforeCreate() {
    if (!this.$store.state.user.phoneNumber) {
      this.$router.push({ name: "home" })
    }
  },
  computed: {
    steps() {
      return [
        {
          component: ChoiceComponent,
          code: this.codes.CHOICE_CODE,
        },
        {
          component: AuthConfirm,
          code: this.codes.AUTH_CONFIRM_CODE,
        },
        {
          component: CardSet,
          code: this.codes.CARD_SET_CODE,
        },
        {
          component: QuestionsComponent,
          code: this.codes.QUESTIONS_CODE,
          isBonusCard: true,
        },
        {
          component: EmailConfirm,
          code: this.codes.EMAIL_CHANGE,
          isBonusCard: true,
        },
        {
          component: FinishCardSet,
          code: this.codes.FINISH_CODE,
        },
      ]
    },
    activeStep() {
      return this.steps.find(item => item.code === this.activeStepCode) || {}
    },
    codes() {
      return this.$store.state.codes.bonus_cards;
    }
  },
  methods: {
    setActive(code) {
      this.activeStepCode = code
    }
  },
}
</script>

<style lang="stylus">
.reg {
  justify-content center

  &__content {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    background: var(--color_white);
    border-radius: 10px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
    +below(380px) {
      padding 20px
    }
  }
}
</style>